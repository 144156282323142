<template>
	<div class="web-content">
		<Search :term="false" :isSocial="true" :isSearch="false" :classlist="classlist" ref="search"
			@handleQuery="handleQuery" />

		<el-row type="flex" justify="space-between">
			<el-col :span="6">
				<h3>社会实践</h3>
			</el-col>
			<el-col :span="18" class="btn" style="text-align: right">
				<el-button type="primary" plain><download-excel :data="tableData" :fields="fields" name="社会实践.xls">
						导出
					</download-excel>
				</el-button>
			</el-col>
		</el-row>

		<el-table :data="tableData" style="width: 100%" border :header-cell-style="tableHeaderColor"
			:cell-style="cellStyleFun">
			<el-table-column type="index" label="序号" width="50"> </el-table-column>
			<el-table-column prop="practiceTitle" label="社会实践主题" width="160">
			</el-table-column>
			<el-table-column prop="practiceLabel" label="指标标签" width="130">
			</el-table-column>
			<el-table-column prop="practiceType" label="实践类别" width="120">
			</el-table-column>
			<el-table-column prop="practiceAddress" label="实践地点">
			</el-table-column>
			<el-table-column prop="practiceTime" label="实践时间" width="100"> </el-table-column>
			<el-table-column prop="publishStatus" label="发布状态"> </el-table-column>
			<el-table-column prop="status" label="状态">
				<template slot-scope="scope">
					<span v-if="scope.row.practiceType != '基地实践'">{{ ['启用', '禁用'][scope.row.status] }}</span>
					<span v-else>--</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="200">
				<template slot-scope="scope">
					<el-button @click="handleDetail(scope.row)" type="text" size="small">查看详情</el-button>
					<el-button @click="handleExperience(scope.row)" type="text" size="small">心得感悟</el-button>
					<el-button v-if="scope.row.publishStatus == '已发布'" @click="handleSupplement(scope.row)" type="text"
						size="small">补录学生</el-button>
					<el-button v-if="scope.row.publishStatus == '未发布'" @click="handlePublish(scope.row)" type="text"
						size="small">发布</el-button>
				</template>
			</el-table-column>
		</el-table>

		<pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize"
			@pagination="getList" />

		<!-- 查看详情 -->
		<el-dialog title="团体社会实践详情" :visible.sync="open" width="600px" center>
			<el-form ref="detailForm" :model="detailForm">
				<el-form-item label="社会实践主题：">
					<span class="scontent">{{ detailForm.practiceTitle }}</span>
				</el-form-item>
				<el-form-item label="指标标签：">
					<span class="scontent">{{ detailForm.practiceLabel }}</span>
				</el-form-item>
				<el-form-item label="实践时间：">
					<span class="scontent">{{ detailForm.practiceTime }}</span>
				</el-form-item>
				<el-form-item label="实践地点：">
					<span class="scontent">{{ detailForm.practiceAddress }}</span>
				</el-form-item>
				<div v-if="detailForm.publishStatus == '已发布'">
					<el-form-item label="参与学生：">
						<el-button size="mini" type="primary" @click="innerVisible = true">查看</el-button>
						<el-dialog width="500px" title="参与学生" :visible.sync="innerVisible" append-to-body center>
							<div class="dialog-name">
								<h3>学生名单（共{{ detailForm.userNames.length }}人）</h3>
								<ul style="display: flex; flex-wrap: wrap">
									<li v-for="(item, index) in detailForm.userNames" :key="index" style="
                      font-size: 14px;
                      color: #303133;
                      line-height: 48px;
                      margin-right: 16px;
                    ">
										{{ item }}
									</li>
								</ul>
							</div>
							<div slot="footer" class="dialog-footer">
								<el-button @click="innerVisible = false">关 闭</el-button>
							</div>
						</el-dialog>
					</el-form-item>
					<el-form-item label="实践说明：" prop="practiceRemark">
						<span class="scontent">{{ detailForm.practiceRemark }}</span>
					</el-form-item>
					<el-form-item label="实践图片：">
						<el-image v-for="(item, index) in detailForm.practiceImg" :key="index" fit="contain"
							:src="imgSrc + item"></el-image>
					</el-form-item>
					<el-divider content-position="left">实践内容</el-divider>
					<div class="scontent" v-html="detailForm.practiceContext"></div>
				</div>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="open = false">关 闭</el-button>
				<el-button type="primary" v-if="detailForm.publishStatus != '未发布'" plain @click="editDetail">修改</el-button>
				<el-button v-if="detailForm.publishStatus == '未发布'" type="primary" @click="handleGoPub">去发布</el-button>
			</div>
		</el-dialog>

		<!-- 发布 -->
		<el-dialog title="团体社会实践详情" :visible.sync="openPublish" width="600px" @opened="initEditor('editor')" center>
			<el-form ref="form" :model="form" :rules="rules" style="
          border-top: 1px solid #e1e3e9;
          border-bottom: 1px solid #e1e3e9;
          padding: 5px 0 10px 0;
        ">
				<el-form-item label="社会实践主题：">
					<span class="scontent">{{ form.practiceTitle }}</span>
				</el-form-item>
				<el-form-item label="指标标签：">
					<span class="scontent">{{ form.practiceLabel }}</span>
				</el-form-item>
				<el-form-item label="实践时间：">
					<span class="scontent">{{ form.practiceTime }}</span>
				</el-form-item>
				<el-form-item label="实践地点：">
					<span class="scontent">{{ form.practiceAddress }}</span>
				</el-form-item>
				<el-form-item label="参与班级：">
					<span class="scontent">{{ form.className }}</span>
				</el-form-item>
				<el-form-item label="参与学生" prop="studentIds" style="margin-bottom: 18px">
					<div class="student-box">
						<el-row type="flex" justify="space-around" style="border-bottom: 1px solid #e1e3e9">
							<el-col style="padding-left: 15px">
								<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
									@change="handleCheckAllChange">全选</el-checkbox>
							</el-col>
							<el-col style="text-align: right; padding-right: 15px">
								已选<span style="color: #569df8">{{ students }}</span>人
							</el-col>
						</el-row>
						<ul style="padding: 0px 15px">
							<el-checkbox-group v-model="form.studentIds" @change="handleCheckedCitiesChange">
								<p v-if="classStudent.length <= 0">暂无数据</p>
								<li v-for="(stu, sin) in classStudent" :key="sin">
									<el-checkbox :label="stu.userId">{{ stu.userName }} {{ stu.loginName }}</el-checkbox>
								</li>
							</el-checkbox-group>
						</ul>
					</div>
				</el-form-item>

				<el-form-item label="实践图片：" style="border-top: 1px dashed #e1e3e9; padding-top: 5px">
					<div>
						<el-upload action="nothing" accept="image/*" list-type="picture-card" :limit="3" :multiple="true"
							:file-list="form.fileList" :on-change="handleUpChange" :on-remove="handleRemove" :on-exceed="handleExceed"
							:auto-upload="false">
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>
				</el-form-item>

				<el-form-item label="实践内容描述：" prop="practiceContext">
					<div id="editor" class="edtr" ref="editor"></div>
				</el-form-item>

			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="openPublish = false">关 闭</el-button>
				<el-button type="primary" @click="handlePublishSubmit">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 补录学生 -->
		<el-dialog title="补录学生" :visible.sync="openSupplement" width="600px" center>
			<el-form ref="formSup" :model="form" :rules="rules" style="
          border-top: 1px solid #e1e3e9;
          border-bottom: 1px solid #e1e3e9;
          padding: 5px 0 10px 0;
        ">
				<el-form-item label="社会实践主题：">
					<span class="scontent">{{ form.practiceTitle }}</span>
				</el-form-item>
				<el-form-item label="指标标签：">
					<span class="scontent">{{ form.practiceLabel }}</span>
				</el-form-item>
				<el-form-item label="实践时间：">
					<span class="scontent">{{ form.practiceTime }}</span>
				</el-form-item>
				<el-form-item label="实践地点：">
					<span class="scontent">{{ form.practiceAddress }}</span>
				</el-form-item>
				<el-form-item label="参与学生" prop="studentIds" style="margin-bottom: 18px">
					<div class="student-box">
						<el-row type="flex" justify="space-around" style="border-bottom: 1px solid #e1e3e9">
							<el-col style="padding-left: 15px">
								<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
									@change="handleCheckAllChange">全选</el-checkbox>
							</el-col>
							<el-col style="text-align: right; padding-right: 15px">
								已选<span style="color: #569df8">{{ students }}</span>人
							</el-col>
						</el-row>
						<ul style="padding: 0px 15px">
							<el-checkbox-group v-model="form.studentIds" @change="handleCheckedCitiesChange">
								<p v-if="classStudent.length <= 0">暂无数据</p>
								<li v-for="(stu, sin) in classStudent" :key="sin">
									<el-checkbox :label="stu.userId">{{ stu.userName }} {{ stu.loginName }}</el-checkbox>
								</li>
							</el-checkbox-group>
						</ul>
					</div>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="openSupplement = false">关 闭</el-button>
				<el-button type="primary" @click="handleSupplementSubmit">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 重新编辑实践内容 -->
		<el-dialog title="团体社会实践编辑" :visible.sync="modal.edit" @opened="initEditor('editor1')" width="600px" center>
			<el-form ref="redoDetailForm" :model="redoDetailForm" :rules="rules" style="
	      border-top: 1px solid #e1e3e9;
	      border-bottom: 1px solid #e1e3e9;
	      padding: 5px 0 10px 0;
	    ">
				<el-form-item label="社会实践主题：">
					<span class="scontent">{{ redoDetailForm.practiceTitle }}</span>
				</el-form-item>
				<el-form-item label="指标标签：">
					<span class="scontent">{{ redoDetailForm.practiceLabel }}</span>
				</el-form-item>
				<el-form-item label="实践时间：">
					<span class="scontent">{{ redoDetailForm.practiceTime }}</span>
				</el-form-item>
				<el-form-item label="实践地点：">
					<span class="scontent">{{ redoDetailForm.practiceAddress }}</span>
				</el-form-item>
				<div v-if="redoDetailForm.publishStatus == '已发布'">
					<el-form-item label="参与学生：">
						<el-button size="mini" type="primary" @click="modal.seeStu = true">查看</el-button>
						<el-dialog width="500px" title="参与学生" :visible.sync="modal.seeStu" append-to-body center>
							<div class="dialog-name">
								<h3>学生名单（共{{ redoDetailForm.userNames.length }}人）</h3>
								<ul style="display: flex; flex-wrap: wrap">
									<li v-for="(item, index) in redoDetailForm.userNames" :key="index" style="
	                  font-size: 14px;
	                  color: #303133;
	                  line-height: 48px;
	                  margin-right: 16px;
	                ">
										{{ item }}
									</li>
								</ul>
							</div>
							<div slot="footer" class="dialog-footer">
								<el-button @click="modal.seeStu = false">关 闭</el-button>
							</div>
						</el-dialog>
					</el-form-item>
					<el-form-item label="实践图片：" style="border-top: 1px dashed #e1e3e9; padding-top: 5px">
						<div>
							<el-upload action="nothing" accept="image/*" list-type="picture-card" :limit="3" :multiple="true"
								:file-list="form.fileList" :on-change="handleUpChange" :on-remove="handleRemove"
								:on-exceed="handleExceed" :auto-upload="false">
								<i class="el-icon-plus"></i>
							</el-upload>
						</div>
					</el-form-item>

					<el-form-item label="实践内容描述：" prop="practiceContext">
						<div id="editor1" class="edtr" ref="editor1"></div>
					</el-form-item>
				</div>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="modal.edit = false">关 闭</el-button>
				<el-button type="primary" plain @click="redoPractice">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Search from "@/components/Search";
	import {
		getSocial,
		getSocialDetail,
		publishSocial,
		socialSupplement,
		getSupplementStudent,
	} from "@/api/teacher/quality/social";
	import {
		getClassStudent
	} from "@/api/record/group/group";
	import {
		getToken
	} from "@/utils/auth"
	import E from "wangeditor"
	export default {
		data() {
			return {
				imgSrc: this.imageSrc(),
				total: 0,
				queryParams: {
					pageSize: 100,
				},
				classlist: [],
				classStudent: [],
				tableData: [],
				open: false,
				openPublish: false,
				openSupplement: false,
				innerVisible: false,
				detailForm: {
					userNames: [],
				},
				redoDetailForm: {},
				modal: {
					edit: false,
					seeStu: false
				},
				rowForm: {},
				form: {
					studentIds: [],
					fileList: [],
					userIds: "",
				},
				rules: {
					studentIds: [{
						required: true,
						message: "请选择参与学生",
						trigger: "change"
					}, ],
					practiceContext: [{
						required: true,
						message: "请输入实践内容",
						trigger: "blur"
					}, ],
				},
				students: 0,
				checkAll: false,
				isIndeterminate: false,
				fields: {
					社会实践主题: "practiceTitle",
					指标标签: "practiceLabel",
					实践类别: "practiceType",
					实践地点: "practiceAddress",
					实践时间: "practiceTime",
					发布状态: "publishStatus",
				},
				editor: {}
			};
		},
		components: {
			Search
		},
		methods: {
			getFirst() {
				this.$store.dispatch("GetClassList", this.typeId).then((res) => {
					this.classlist = res.data;
					this.queryParams.termName = "";
					this.queryParams.classId = this.$store.getters.queryForm.deptId;
					this.$refs.search.getDicByPractice();
					this.$refs.search.init(
						this.queryParams.termName,
						this.queryParams.classId
					);
					this.getList();
				});
			},
			handleQuery(data) {
				this.queryParams.classId = data.deptId;
				this.queryParams.practiceType = data.practiceType;
				this.queryParams.practiceLabel = data.practiceLabel;
				this.queryParams.publishStatus = data.publishStatus;
				this.getList();
			},
			getList() {
				getSocial(this.queryParams).then((res) => {
					this.tableData = res.data.list;
					this.total = res.data.total;
				});
			},
			handleDetail(row) {
				getSocialDetail({
					recordId: row.recordId,
					classId: row.classId
				}).then(
					(res) => {
						this.detailForm = res.data;
						this.open = true;
						this.rowForm = row;
						if (this.detailForm.practiceImg) {
							this.detailForm.practiceImg =
								this.detailForm.practiceImg.split(",");
						}
					}
				);
			},
			handleGoPub() {
				this.open = false;
				this.handlePublish(this.rowForm);
			},
			handleExperience(row) {
				if (row.publishStatus == "已发布") {
					const params = row;
					this.$router.push({
						name: "experience",
						params: params
					});
					sessionStorage["params"] = JSON.stringify(params);
				} else if (row.publishStatus == "未发布") {
					this.$message.warning("请先发布该社会实践");
				}
			},
			// 补录
			handleSupplement(row) {
				getSupplementStudent({
					id: row.recordId,
					classId: row.classId
				}).then(
					(res) => {
						this.classStudent = res.data;
						this.reset();
						this.openSupplement = true;
						this.form.recordId = row.recordId;
						this.form.practiceTitle = row.practiceTitle;
						this.form.practiceLabel = row.practiceLabel;
						this.form.practiceTime = row.practiceTime;
						this.form.practiceAddress = row.practiceAddress;
						this.form.classId = row.classId;
					}
				);
			},
			handleSupplementSubmit() {
				this.$refs["formSup"].validate((valid) => {
					if (valid) {
						socialSupplement({
							id: this.form.recordId,
							classId: this.form.classId,
							userIds: this.form.studentIds,
						}).then((res) => {
							this.$message.success("补录成功");
							this.openSupplement = false;
							this.getList();
						});
					} else {
						return false;
					}
				});
			},
			// 修改社会实践内容
			editDetail() {
				this.redoDetailForm = JSON.parse(JSON.stringify(this.detailForm))
				if (!this.isEmpty(this.redoDetailForm.practiceImg)) {
					this.redoDetailForm.practiceImg.forEach(v => {
						this.form.fileList.push({
							name: v,
							url: this.imgSrc + v
						})
					})
				}
				this.modal.edit = true
			},
			// 社会实践修改后提交
			redoPractice() {
				this.$refs["redoDetailForm"].validate((valid) => {
					if (valid) {
						let formData = new FormData()
						if (!this.isEmpty(this.form.file)) {
							this.form.file = this.compressImg(this.form.file)
							this.form.file.map((item) => {
								formData.append("file", item)
							})
						}
						formData.append("practiceContext", this.redoDetailForm.practiceContext);
						formData.append("recordId", this.redoDetailForm.recordId);
						formData.append("classId", this.redoDetailForm.classId)
						publishSocial(formData).then((res) => {
							this.$message.success("修改成功")
							this.form.fileList = []
							this.modal.edit = false;
							this.open = false
							this.getList()
						});
					} else {
						return false;
					}
				})
			},
			// 发布
			handlePublish(row) {
				if (row.practiceType == '家校实践' && row.publishStatus == '未发布') {
					this.$message.warning('学校管理员尚未发布家校实践')
				} else {
					this.reset();
					this.getStudent();
					this.openPublish = true;
					this.form.recordId = row.recordId;
					this.form.practiceTitle = row.practiceTitle;
					this.form.practiceLabel = row.practiceLabel;
					this.form.practiceTime = row.practiceTime;
					this.form.practiceAddress = row.practiceAddress;
					this.form.classId = row.classId;
					this.classlist.map((item) => {
						if (item.deptId == this.form.classId) {
							this.form.className = item.deptName;
						}
					});
				}
			},
			handlePublishSubmit() {
				if (this.form.file) {
					this.form.file = this.compressImg(this.form.file);
				}
				setTimeout(() => {
					this.$refs["form"].validate((valid) => {
						if (valid) {
							for (let i = 0; i < this.form.studentIds.length; i++) {
								if (i == this.form.studentIds.length - 1) {
									this.form.userIds += this.form.studentIds[i];
								} else {
									this.form.userIds += this.form.studentIds[i] + ",";
								}
							}
							var formData = new FormData();
							if (this.form.file != undefined) {
								this.form.file.map((item) => {
									formData.append("file", item);
								});
							}
							formData.append("practiceContext", this.form.practiceContext);
							formData.append("recordId", this.form.recordId);
							formData.append("classId", this.form.classId);
							formData.append("userIds", this.form.userIds);
							publishSocial(formData).then((res) => {
								this.$message.success("发布成功");
								this.form.fileList = []
								this.isIndeterminate = false
								this.checkAll = false
								this.openPublish = false;
								this.getList();

							});
						} else {
							return false;
						}
					});
				}, 1000);
			},
			reset() {
				this.resetForm("form");
				this.resetForm("formSup");
				this.form = {
					studentIds: [],
					fileList: [],
					file: undefined,
					practiceContext: undefined,
					userIds: "",
				};
			},
			// 全选
			handleCheckAllChange(val) {
				const arr = [];
				this.form.studentName = "";
				if (val) {
					this.classStudent.map((item) => {
						arr.push(item.userId);
						this.form.studentName += item.userName + ",";
					});
					this.students = arr.length;
				} else {
					this.students = 0;
				}
				this.form.studentIds = val ? arr : [];
				this.isIndeterminate = false;
				// console.log(this.form.studentName, "全选");
			},
			// 单选
			handleCheckedCitiesChange(value) {
				this.form.studentName = "";
				this.students = value.length;
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.classStudent.length;
				this.isIndeterminate =
					checkedCount > 0 && checkedCount < this.classStudent.length;
				value.map((check) => {
					this.classStudent.map((item) => {
						if (check == item.userId) {
							this.form.studentName += item.userName + ",";
						}
					});
				});
				// console.log(this.form.studentName, "单选");
			},
			// 上传图片
			handleUpChange(file, fileList) {
				var _this = this;
				_this.form.file = fileList;
				if (file.size / 1024 > 5 * 1024) {
					this.$message.warning("图片大小不得超过5MB");
					this.handleRemove(file);
				}
				// console.log(_this.form.file,'file');
			},
			handleRemove(file, fileList) {
				this.form.file.forEach((item, index) => {
					if (item.name === file.raw.name) {
						this.form.file.splice(index, 1);
					}
				});
			},
			handleExceed() {
				this.$message.warning("最多只能上传3张图片");
			},
			// 获取学生列表
			getStudent() {
				getClassStudent({
					deptId: this.queryParams.classId
				}).then((res) => {
					this.classStudent = res.data;
				});
			},
			tableHeaderColor({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (rowIndex === 0) {
					return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
				}
			},
			cellStyleFun() {
				return "text-align:center";
			},
			// 富文本手动设置内容
			setContent(edtrId, v) {
				// 发布
				if (edtrId == 'editor') {
					this.form.practiceContext = v
				} else if (edtrId == 'editor1') {
					// 发布后修改
					this.redoDetailForm.practiceContext = v
				}
			},
			// 初始化编辑器
			initEditor(editorId) {
				var that = this;
				// 销毁已有editor实例
				if (!that.isEmpty(that.editor.id)) {
					that.editor.destroy()
					that.editor = null
				}
				that.editor = new E(that.$refs[editorId])
				// 编辑器的事件，每次改变会获取其html内容
				that.editor.config.onchange = (html) => {
					that.form.practiceContext = html;
					that.setContent(editorId, that.form.practiceContext); // 通过setContent方法改变内容
				};
				that.editor.config.height = 300;
				that.editor.config.showLinkImg = false;
				that.editor.config.debug = true;
				that.editor.config.uploadImgServer =
					process.env.VUE_APP_API + "/shida-user-biz/upload/uploadImage";
				that.editor.config.uploadFileName = "file";
				that.editor.config.placeholder = "请输入实践内容描述";
				that.editor.config.uploadImgHeaders = {
					Authorization: getToken(),
					'role-distinction-client': 2
				};
				that.editor.config.uploadImgHooks = {
					// 图片上传并返回结果，但图片插入错误时触发
					fail: function(xhr, editor, result) {
						console.error(result);
					},
					success: function(xhr, editor, result) {
						// 图片上传并返回结果，图片插入成功之后触发
					},
					// 图片上传并返回了结果，想要自己把图片插入到编辑器中
					// 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
					customInsert: function(insertImgFn, result) {
						// result 即服务端返回的接口
						// insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
						insertImgFn(that.imgSrc + result.data);
					},
				};
				that.editor.config.menus = [
					// 菜单配置
					"bold", // 粗体
					"fontSize", // 字号
					"italic", // 斜体
					"underline", // 下划线
					"strikeThrough", // 删除线
					"foreColor", // 文字颜色
					"backColor", // 背景颜色
					"link", // 链接
					"undo", // 撤销
					"redo", // 重做
				];
				that.editor.create(); // 创建富文本实例
				// 如果是修改社会实践详情
				if (editorId == 'editor1' && !that.isEmpty(that.redoDetailForm)) {
					that.editor.txt.html(that.redoDetailForm.practiceContext)
				}
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/el-tabs.scss";
	@import "@/assets/scss/studentResult.scss";

	.web-content {
		width: 100%;

		.student-box {
			max-height: 343px;
			background: #f9fafc;
			border: 1px solid #e1e3e9;
			border-radius: 4px;
			overflow: auto;
		}
	}
</style>
<style scoped>
	.el-form-item {
		margin-bottom: 12px;
	}

	.el-form-item>>>.el-form-item__label {
		font-weight: bold;
		color: #303133;
	}

	.el-form>>>.el-upload--picture-card {
		width: 100px;
		height: 100px;
		line-height: 100px;
	}

	.el-form>>>.el-upload-list__item {
		width: 100px;
		height: 100px;
	}

	.edtr {
		clear: both;
	}

	/* .el-image {
  width: 300px;
} */
</style>